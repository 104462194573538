import React from 'react'
import tw, { styled } from 'twin.macro'
import Navbar from '../../components/elements/Navbar'
import { Subtitle } from '../datenschutz'
import components from '../../content/common'
import Footer from '../../components/elements/Footer'
import presseImage1 from '@images/presse/brauhaus-vanhans-preview.jpg'

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-20 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const ContentWrap = tw.div`max-w-master mx-auto`
const ContentRow = tw.div`grid grid-cols-12 gap-4 mt-4`
const ContentImageWrapper = tw.div`flex justify-center`
const ContentText = tw.div`col-span-12 xs:col-span-8`
const ImageSource = tw.small`text-white`
const ContentRowImage = tw.div`col-span-12`

const PressePage = () => {
  const presseImageDl1 = '/download/brauhaus-vanhans.jpg';

  return (
    <>
      <Navbar content={components.navbar} />
        <Section>
          <ContentWrap>
            <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
              <Subtitle tw="max-w-full">
                Let’s nail it! ERDINGER Brauhaus x Einhell
              </Subtitle>
              <ContentText>
                <p>
                  Erding, Dezember 2023<br />
                  Handwerkskunst meets Braukunst! ERDINGER Brauhaus kooperiert mit
                  VanHans, der neuen jungen Marke und DIY-Community von Einhell, einem der globalen Marktführer 
                  in der Do-It-Yourself-Branche. Zur Bekanntgabe der Partnerschaft am Montag, den 04. Dezember, hieß 
                  es im ERDINGER Weißbräu Besucherzentrum offiziell: Let’s nail it! Denn die ERDINGER Brauhaus-Edition 
                  des VanHans Nagelstocks wurde symbolisch übergeben. Dr. Markus Thannhuber, CTO & 
                  Director der Einhell Germany AG, überreichte den in Handarbeit gefertigten Holzstamm, in dessen 
                  Schnittfläche es beim Party-Spiel „Nageln“ mit möglichst wenig Versuchen Nägel einzuschlagen gilt, an 
                  Dr. Stefan Kreisz, Vorsitzender der Geschäftsführung bei der Privatbrauerei ERDINGER Weißbräu. Nach
                  der feierlichen Übergabe wurde gemeinsam auf die neue Partnerschaft angestoßen und der 
                  Nagelstock bei einer ersten Runde „Nageln“ eingeweiht.
                </p>
                <p>
                  VanHans und ERDINGER Brauhaus stehen für vollkommen neue Markenwelten zweier bayerischer 
                  Traditionsunternehmen. „Mit unserer Partnerschaft feiern wir die Liebe zum Handwerk – sei es das 
                  Brauen oder das Heimwerken. Gemeinsam wollen wir Tradition zukunftsfähig machen und zeigen, dass 
                  diese frisch, zeitgemäß und spritzig sein kann – genau wie unsere ERDINGER Brauhaus-Biere“, erklärt
                  Dr. Stefan Kreisz. Dr. Markus Thannhuber ist ebenfalls begeistert: „Mit VanHans haben wir eine Marke 
                  geschaffen, die ein neues DIY-Zeitalter einläutet. Jahrelanges Know-how trifft hier auf eine junge, 
                  trendbewusste Community mit Leidenschaft fürs Handwerk. Da passt ERDINGER Brauhaus natürlich 
                  perfekt. Zusammen können wir gerade in der jüngeren Zielgruppe für Furore sorgen und unsere 
                  Reichweiten ausbauen. Unsere Communities sprechen die gleiche Sprache.“
                </p>
                <p>
                  Geplant sind kreative Social-Media-Aktionen, die die Fans beider Marken zusammenbringen. Sie 
                  dürfen sich auf originellen Content freuen, der das Beste aus Handwerks- und Braukunst zeigt. Zudem 
                  wird der VanHans Nagelstock in der Brauhaus-Edition inklusive Gratis-Kasten ERDINGER Brauhaus über 
                  den ERDINGER Fanshop bei VanHans erhältlich sein – ein echtes Highlight für jede Party.
                </p>
                <p>
                  Homepages:<br />
                  <a href="https://www.erdinger-brauhaus.de/" target="_blank">erdinger-brauhaus.de</a>
                  <span> & </span> <a href="https://www.vanhans.com/" target="_blank">vanhans.com</a><br />
                </p>
                <br />
              </ContentText>

              <ContentImageWrapper>
                <div>
                  <a 
                      href={presseImageDl1}
                      target="_blank"
                    >
                      <img src={presseImage1} alt="Dr. Markus Thannhuber überreicht die ERDINGER Brauhaus-Edition des VanHans Nagelstocks an Dr. Stefan Kreisz" />
                  </a>
                  <ImageSource>Bildrechte: ERDINGER Weißbräu</ImageSource>
                </div>
              </ContentImageWrapper>
              <p>Dr. Markus Thannhuber, CTO & Director der Einhell Germany AG, (rechts) überreicht die ERDINGER Brauhaus-Edition des VanHans Nagelstocks an Dr. Stefan Kreisz, Vorsitzender der Geschäftsführung bei der Privatbrauerei ERDINGER Weißbräu, (links).</p>

            </div>
          </ContentWrap>
        </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default PressePage
